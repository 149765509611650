var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-query"},[_c('header',{staticClass:"page-header"},[_c('van-icon',{attrs:{"name":"arrow-left"},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('span',[_vm._v(_vm._s(_vm.title))]),_c('span')],1),_c('main',{staticClass:"page-body"},[_c('div',{staticClass:"flexbox"},[_c('van-search',{staticClass:"flex-fill",attrs:{"placeholder":"请输入搜索关键词"},on:{"search":_vm.onSearch},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),(_vm.list.length > 0)?_c('section',{staticClass:"padding"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"card"},[(_vm.$route.query.type === 'order')?[_c('header',{staticClass:"flexbox space-bt card-header"},[_c('div',[_vm._v("订单："+_vm._s(_vm._f("empty")(item.sOrderNo)))]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("time")(item.createtime)))]),_c('div',{staticClass:"theme-color"},[_vm._v(_vm._s(item.StatusDesc))])]),_c('div',{staticClass:"card-body flexbox space-bt",on:{"click":function($event){return _vm.$router.push({
                name: 'OrderDetail',
                query: { orderno: item.sOrderNo },
              })}}},[_c('div',[_c('div',[_vm._v(" 收件人："),_c('span',{staticClass:"theme-color"},[_vm._v(_vm._s(item.sRecName))])]),_c('div',[_c('span',[_vm._v(" 收货电话："),_c('span',{staticClass:"theme-color"},[_vm._v(" "+_vm._s(item.sRecTel))])])]),_c('div',[_vm._v(" 收件地址："+_vm._s((item.sCnCountryName == null ? '' : item.sCnCountryName) + ' ' + (item.sRecCity == null ? '' : item.sRecCity) + ' ' + (item.sAddr1 == null ? '' : item.sAddr1) + ' ' + (item.sAddr2 == null ? '' : item.sAddr2))+" ")]),_c('div',[_vm._v("物品名称："+_vm._s(_vm._f("empty")(item.goods_name)))]),_c('div',[_vm._v("备注："+_vm._s(_vm._f("empty")(item.remark)))]),_c('div',[_vm._v("货值："+_vm._s(_vm._f("empty")(item.fDeclareValue)))]),_c('div',{staticClass:"flexbox"},[(_vm.userInfo.bWatchVol == true && item.totalVol > 0)?_c('div',[_vm._v(" 体积："),_c('span',{staticClass:"order-info-item"},[_vm._v(_vm._s(_vm._f("empty")(item.totalVol)))])]):_vm._e(),(_vm.userInfo.bWatchVol == true && item.totalWeight > 0)?_c('div',[_vm._v(" 重量："),_c('span',{staticClass:"order-info-item"},[_vm._v(_vm._s(_vm._f("empty")(item.totalWeight)))])]):_vm._e(),_c('div',[_vm._v(" 箱数："),_c('span',{staticClass:"order-info-item"},[_vm._v(_vm._s(_vm._f("empty")(item.totalBags)))])])])])]),_c('footer',{staticClass:"card-footer"},[_c('span',{staticClass:"pull-left"},[_vm._v("目的地："),_c('span',{staticClass:"round-label"},[_vm._v(" "+_vm._s(item.targetPlace))])]),_c('a',{staticClass:"round-label",attrs:{"href":'/tracks?key=' + item.sOrderNo}},[_vm._v("轨迹详情")]),_c('span',{staticClass:"pull-right"},[_vm._v("运输方式: "),_c('span',{staticClass:"round-label"},[_vm._v(" "+_vm._s(item.transType))])])])]:[_c('div',{staticClass:"card-body flexbox space-bt",on:{"click":function($event){if (item.istatus <= 0) {
                _vm.$router.push({
                  name: 'PackageEdit',
                  query: { id: item.PK_ID },
                });
              }}}},[_c('div',[_c('div',[_vm._v("快递单号："+_vm._s(_vm._f("empty")(item.delivery_no)))]),_c('span',[_vm._v("进仓编号："+_vm._s(_vm._f("empty")(item.sCode)))]),_c('div',[_vm._v("入仓日期："+_vm._s(_vm._f("empty")(item.createtime)))]),_c('div',[_vm._v("物品名称："+_vm._s(item.goods_name))]),_c('div',[_vm._v("订单编号："+_vm._s(_vm._f("empty")(item.OrderNo)))]),_c('div',[_vm._v("单个货值："+_vm._s(_vm._f("empty")(item.single_price)))]),_c('div',[_vm._v("整体货值："+_vm._s(_vm._f("empty")(item.fDeclareAmount_LC)))])]),_c('img',{class:item.sPicUrl == '' ? 'noimg' : 'img',attrs:{"src":item.sPicUrl == '' ? _vm.emptyImg : _vm.tms_img_url + item.sPicUrl,"alt":""},on:{"click":function($event){return _vm.onImgPreview(item.sPicUrl)}}})]),_c('footer',{staticClass:"card-footer"},[_vm._v(" 箱数："),_c('span',{staticClass:"foot-desc"},[_vm._v(_vm._s(item.iBags))])])]],2)}),0):_c('section',{staticClass:"padding no-item"},[_c('div',{},[_c('img',{attrs:{"src":_vm.emptyImg}})]),_c('div',{staticClass:"nodesc"},[_vm._v("暂时没有记录")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }