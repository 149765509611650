<template>
  <div class="order-query">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>{{ title }}</span>
      <span></span>
    </header>
    <main class="page-body">
      <div class="flexbox">
        <van-search
          class="flex-fill"
          v-model="keyword"
          placeholder="请输入搜索关键词"
          @search="onSearch"
        />
      </div>
      <section v-if="list.length > 0" class="padding">
        <div class="card" v-for="(item, index) in list" :key="index">
          <template v-if="$route.query.type === 'order'">
            <header class="flexbox space-bt card-header">
              <div>订单：{{ item.sOrderNo | empty }}</div>
              <div class="date">{{ item.createtime | time }}</div>
              <div class="theme-color">{{ item.StatusDesc }}</div>
            </header>
            <div
              class="card-body flexbox space-bt"
              @click="
                $router.push({
                  name: 'OrderDetail',
                  query: { orderno: item.sOrderNo },
                })
              "
            >
              <div>
                <div>
                  收件人：<span class="theme-color">{{ item.sRecName }}</span>
                </div>
                <div>
                  <span>
                    收货电话：<span class="theme-color">
                      {{ item.sRecTel }}</span
                    ></span
                  >
                </div>
                <div>
                  收件地址：{{
                    (item.sCnCountryName == null ? '' : item.sCnCountryName) +
                    ' ' +
                    (item.sRecCity == null ? '' : item.sRecCity) +
                    ' ' +
                    (item.sAddr1 == null ? '' : item.sAddr1) +
                    ' ' +
                    (item.sAddr2 == null ? '' : item.sAddr2)
                  }}
                </div>
                <div>物品名称：{{ item.goods_name | empty }}</div>
                <div>备注：{{ item.remark | empty }}</div>
                <div>货值：{{ item.fDeclareValue | empty }}</div>
                <div class="flexbox">
                  <div v-if="userInfo.bWatchVol == true && item.totalVol > 0">
                    体积：<span class="order-info-item">{{
                      item.totalVol | empty
                    }}</span>
                  </div>
                  <div
                    v-if="userInfo.bWatchVol == true && item.totalWeight > 0"
                  >
                    重量：<span class="order-info-item">{{
                      item.totalWeight | empty
                    }}</span>
                  </div>
                  <div>
                    箱数：<span class="order-info-item">{{
                      item.totalBags | empty
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <span class="pull-left"
                >目的地：<span class="round-label">
                  {{ item.targetPlace }}</span
                >
              </span>
              <a class="round-label" :href="'/tracks?key=' + item.sOrderNo"
                >轨迹详情</a
              >
              <span class="pull-right"
                >运输方式:
                <span class="round-label"> {{ item.transType }}</span>
              </span>
            </footer>
          </template>
          <template v-else>
            <div
              class="card-body flexbox space-bt"
              @click="
                if (item.istatus <= 0) {
                  $router.push({
                    name: 'PackageEdit',
                    query: { id: item.PK_ID },
                  });
                }
              "
            >
              <div>
                <div>快递单号：{{ item.delivery_no | empty }}</div>
                <span>进仓编号：{{ item.sCode | empty }}</span>
                <div>入仓日期：{{ item.createtime | empty }}</div>
                <div>物品名称：{{ item.goods_name }}</div>
                <div>订单编号：{{ item.OrderNo | empty }}</div>
                <div>单个货值：{{ item.single_price | empty }}</div>
                <div>整体货值：{{ item.fDeclareAmount_LC | empty }}</div>
              </div>
              <img
                @click="onImgPreview(item.sPicUrl)"
                :src="
                  item.sPicUrl == '' ? emptyImg : tms_img_url + item.sPicUrl
                "
                alt=""
                :class="item.sPicUrl == '' ? 'noimg' : 'img'"
              />
            </div>
            <footer class="card-footer">
              箱数：<span class="foot-desc">{{ item.iBags }}</span>
            </footer>
          </template>
        </div>
      </section>
      <section v-else class="padding no-item">
        <div class=""><img :src="emptyImg" /></div>
        <div class="nodesc">暂时没有记录</div>
      </section>
    </main>
  </div>
</template>
<script>
import { getPackageList, getOrderList } from '@/api/deliver.js';
import { BASE_TMS_URL } from '@/api/index.js';
import { getValidTime } from '@/utils/index.js';
import { ImagePreview } from 'vant';

export default {
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      keyword: '',
      list: [],
      emptyImg: require('../assets/images/noinfo.png'),
      userInfo: this.$store.getters.userInfo,
      tms_img_url: BASE_TMS_URL,
    };
  },
  computed: {
    title() {
      const typeKv = {
        package: '我的包裹',
        order: '运单中心',
      };
      const pkgMap = {
        outstock: '待入库',
        '0': '已入库',
        '1': '已发货',
        '2': '待认领',
        '3': '问题件',
        '-10': '全部包裹',
      };
      const orderMap = {
        cancel: '已取消',
        0: '待处理',
        1: '待装车',
        2: '在途',
        3: '已收货',
        6: '问题单',
      };
      const q = this.$route.query;
      if (q.type === 'package') {
        return `${typeKv.package}-${pkgMap[q.status]}`;
      }
      if (q.type === 'order') {
        return `${typeKv.order}-${orderMap[q.status]}`;
      }
      return '订单查询';
    },
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      const q = this.$route.query;
      if (q.type === 'package') {
        var sta = q.status;
        sta = sta == 'outstock' ? -1 : sta;
        getPackageList({ keyword: this.keyword, state: sta }).then(res => {
          res.rows.forEach(it => {
            it.createtime = getValidTime(it.createtime);
          });
          this.list = res.rows;
        });
        return;
      }
      if (q.type === 'order') {
        getOrderList({ keyword: this.keyword, state: q.status }).then(res => {
          this.list = res.rows;
        });
      }
    },
    onImgPreview(imgurl) {
      if (imgurl == '' || imgurl == null) {
        return;
      }
      ImagePreview([imgurl]);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.card {
  padding: rem(20px);
  padding-top: 0;
  border-radius: rem(15px);
  box-shadow: 0px 2px 10px 0px rgba(14, 48, 53, 0.1);
  background-color: #fff;
  .card-header {
    color: #b8b8b8;
    font-size: 12px;
    border-bottom: 1px solid #efefef;
    height: rem(53px);
    line-height: rem(53px);
  }
  .card-body {
    padding: rem(20px) 0;
    border-bottom: 1px solid #efefef;
    line-height: rem(52px);
  }
  .card-footer {
    color: #666;
    padding-top: rem(20px);
    height: rem(30px);
  }
  & + .card {
    margin-top: rem(20px);
  }
  .foot-desc {
    font-size: rem(32px);
    border-color: #673ab7;
    border: 1px solid;
    color: #673ab7;
    padding: 0 10px;
    border-radius: 4px;
    margin-right: 0.26rem;
  }
}
.padding {
  padding: rem(20px);
}
.img {
  width: rem(280px);
  height: rem(150px);
}
.noimg {
  width: auto;
  height: rem(150px);
}

.no-item {
  text-align: center;
  margin: rem(40px);
  img {
    height: rem(249px);
  }
  .no-desc {
    font-size: rem(13px);
    text-align: center;
  }
}

.round-label {
  background: #b9aebb;
  color: #fff;
  padding: 3px;
  border-radius: 3px;
  margin: 0 5px;
}
.order-info-item {
  border-color: #b9aebb;
  border: 1px solid;
  color: #b9aebb;
  padding: 0px 10px;
  border-radius: 4px;
  margin-right: rem(20px);
}
</style>
